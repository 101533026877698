import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { FetchOptions } from 'apollo-link-http/lib/httpLink';
import { defaultDataIdFromObject, InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';

import config from '../config';
import { ENV } from '../lib/consts';

const getGraphQLServerURI = (environment?: string): string => {
  if (environment === ENV.DORKY) {
    return config.graphqlUrlDevelop;
  }

  if (environment === ENV.TORKY) {
    return config.graphqlTestingUrl;
  }

  if (environment === ENV.STORKY) {
    return config.graphqlStagingUrl;
  }

  return config.graphqlUrl;
};

// Client-side Apollo client
const createBrowserClient = (environment?: string) => {
  const httpLinkOptions: FetchOptions = {
    uri: getGraphQLServerURI(environment),
    credentials: 'include', // Important for CSRF
    batchInterval: 10,
  } as FetchOptions;

  const httpLink = createHttpLink(httpLinkOptions);

  // cache for re-using queries with same variables

  const cache = new InMemoryCache({
    // custom idGetter, for caching based on unique uuid
    // @ts-ignore
    dataIdFromObject: (obj) => obj.uuid || defaultDataIdFromObject(obj),
    // For now, leave true, false causing errors. look at https://github.com/apollographql/apollo-client/issues/3219
    addTypename: true,
    cacheRedirects: {},
  });

  // Create the apollo client
  return new ApolloClient({
    link: httpLink,
    cache: cache.restore(window?.__APOLLO_CLIENT__),
    connectToDevTools: true,
    queryDeduplication: true,
  });
};

let clientInstance: ApolloClient<NormalizedCacheObject> = null;

export function getApolloClient(environment?: string) {
  if (clientInstance) {
    return clientInstance;
  }

  clientInstance = createBrowserClient(environment);

  return clientInstance;
}

import { HubspotPersona, Lead } from '../types';
import { HubspotFormFieldsExtended } from './types';
import { getCountries, getIndustry, getProductBusinessModels, getSkills } from './formDataHelpers';
import calcFrontendSalesQualificationScore, { isFreeEmail } from '../calcFrontendSalesQualificationScore';
import { getEmailDomain } from '../emails';

const mapLeadToHubspotFormValues = (
  lead: Partial<Lead>,
  utmParams = {} as Record<string, string>,
): HubspotFormFieldsExtended => {
  const PERSONA_COMPANY = HubspotPersona.COMPANY_PERSONA;
  const emailAddress = (lead?.emailAddress || '').toLowerCase().trim();
  const hubspotSkills = getSkills(lead?.skills || lead?.serviceTypes);
  const hubspotCountries = getCountries(lead?.locations);
  const productBusinessModels = getProductBusinessModels(lead?.productBusinessModel);
  const hubspotIndustry = getIndustry(lead?.industry);
  const frontendSalesQualificationScore = calcFrontendSalesQualificationScore(lead);
  const emailDomain = getEmailDomain(emailAddress);
  const isPersonalEmail = isFreeEmail(emailDomain);

  return {
    marketing_budget_from_basic_brief_in_usd: lead?.estimatedMediaBudget,
    skills: hubspotSkills,
    kpi: lead?.targetKPI,
    mayple_industry: hubspotIndustry,
    countries: hubspotCountries,
    open_for_remote: lead?.preferLocalMarketers,
    utm_source: utmParams?.utm_source || lead?.utm_source,
    utm_medium: utmParams?.utm_medium || lead?.utm_medium,
    utm_campaign: utmParams?.utm_campaign || lead?.utm_campaign,
    utm_content: utmParams?.utm_content || lead?.utm_content,
    utm_term: utmParams?.utm_term || lead?.utm_term,
    gclid_copy: utmParams?.gclid,
    google_ads_gbraid_copy: utmParams?.gbraid,
    google_ads_wbraid_copy: utmParams?.wbraid,
    hs_persona: lead?.hubSpotPersona || PERSONA_COMPANY, // Company Persona
    email: emailAddress,
    phone: lead?.phoneNumber,
    firstname: lead?.firstName,
    lastname: lead?.lastName,
    website: lead?.websiteAddress,
    company: lead?.companyName,
    last_action_in_mayple: lead?.lastActionInMayple,
    did_fill_basic_brief: lead?.didFillBasicBrief,
    product_business_model: productBusinessModels,
    last_update_context: lead?.lastUpdateContext,
    how_did_you_hear_about_mayple: lead?.howDidYouHearAboutMayple,
    traffic_source: lead?.trafficSource,
    lp_traffic_source: lead?.lpTrafficSource,
    partner_key: lead?.partnerKey,
    partnerstack_referral_key: lead?.partnerStackReferralKey,
    requested_an_instant_call: lead?.requestedAnInstantCall,
    frontend_sales_qualification_score: lead?.frontendSalesQualificationScore || frontendSalesQualificationScore,
    is_personal_email: isPersonalEmail,
    external_customer_id: lead?.externalCustomerId,
    ...lead?.hubspotFormProps,
  };
};

export default mapLeadToHubspotFormValues;

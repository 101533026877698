import isNaN from 'lodash/isNaN';

import { Lead } from '../types';

export enum ValidationType {
  MandatoryValues = 'MandatoryValues',
  ValuesTypes = 'ValuesTypes',
}

export type ValidationResult = {
  validationType: ValidationType;
  isValid: boolean;
  fields?: string[];
};

export const validateIsNumericValue = (value: any): boolean => {
  return !(value != null && isNaN(parseFloat(value)));
};

export const validateNoMaypleAllowed = (value: any): boolean => !/mayple/gi.test(value);

export const validateMandatoryFields = (lead: Partial<Lead>): ValidationResult => {
  const mandatoryFields = ['emailAddress', 'country', 'targetKPI'];
  const missingFields: string[] = [];

  mandatoryFields.forEach((field) => {
    if (!Object.prototype.hasOwnProperty.call(lead, field)) {
      missingFields.push(field);
    }
  });

  return {
    validationType: ValidationType.MandatoryValues,
    isValid: missingFields.length === 0,
    fields: missingFields,
  };
};

export const validateFieldsValuesTypes = (lead: Partial<Lead>): ValidationResult => {
  const testFields: Array<{ name: keyof Lead; validationFunc: (value: any) => boolean }> = [
    { name: 'estimatedMediaBudget', validationFunc: validateIsNumericValue },
    { name: 'companyName', validationFunc: validateNoMaypleAllowed },
    { name: 'websiteAddress', validationFunc: validateNoMaypleAllowed },
  ];

  const errorFields: string[] = [];

  testFields.forEach(({ name, validationFunc }) => {
    // Run the validation function for supplied values
    if (!validationFunc(lead?.[name])) {
      errorFields.push(name);
    }

    if (lead[name] != null && isNaN(lead[name])) {
      errorFields.push(name);
    }
  });

  return {
    validationType: ValidationType.ValuesTypes,
    isValid: errorFields.length === 0,
    fields: errorFields,
  };
};

export const fixNumericValue = (value?: string | number | null): number => {
  if (typeof value === 'number') {
    return value;
  }

  // empty string / null / undefined will be returned as 0 (zero)
  if (!value || typeof value !== 'string') {
    return 0;
  }

  // clear spaces and remove none digits characters
  const newValue = value.replace(/[^\d\-.]/gi, '').trim();

  if (!isNaN(newValue) && newValue !== '') {
    return parseFloat(newValue);
  }

  return 0;
};

import { ProjectLeadCreate } from '@mayple/types';
import { ApolloClient } from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';

// @ts-ignore
import createProjectLead from './createProjectLead.graphql';
import ClientLogger from '../../ClientLogger';

/**
 * Creates a project lead token
 * @param apolloClient
 * @param projectLeadCreate
 * @param clientLogger
 * @returns {Promise<boolean>}
 */
const createProjectLeadToken = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  projectLeadCreate: ProjectLeadCreate,
  clientLogger: ClientLogger,
): Promise<string> => {
  clientLogger.debug(`Sending lead data:\n\n${JSON.stringify(projectLeadCreate, null, 2)}`);

  const response = await apolloClient.mutate({
    mutation: createProjectLead,
    variables: {
      projectLeadCreate,
    },
  });

  clientLogger.debug(`Create lead response:\n\n${JSON.stringify(response, null, 2)}`);

  return response?.data?.createProjectLead || '';
};

export default createProjectLeadToken;

import * as psl from 'psl';
import startCase from 'lodash/startCase';
import lowerCase from 'lodash/lowerCase';

import { getEmailDomain } from './emails';
import isFreeEmailDomain from './emails/isFreeEmailDomain';

type SocialNetwork = 'facebook' | 'twitter' | 'linkedin' | 'instagram' | 'patreon';

// https://www.facebook.com/Fiverr --> Fiverr
// https://www.facebook.com/TatranMusic --> TatranMusic
// https://www.facebook.com/cebupacificair/ --> cebupacificair
// https://www.facebook.com/קוקה-קולה-וניל-165911170095974/ --> קוקה-קולה-וניל - (remove the last number)
// https://www.facebook.com/pages/CredCompare/383919875559397 <-- CredCompare (don't take the number after the pagename)
// https://www.instagram.com/ddlovato -> ddlovato
// https://twitter.com/NICKIMINAJ/ --> NICKIMINAJ
// https://www.linkedin.com/in/omer-farkash-182281b4/ -> omer-farkash <- remove the hex part if found
// https://www.linkedin.com/in/tomernosrati/ --> tomernosrati https://www.linkedin.com/company/oracle --> oracle
// https://www.linkedin.com/company/reblaze/ --> reblaze
export const SOCIAL_NETWORK_PAGE_REGEX = /(www\.)?(facebook|twitter|linkedin|instagram|patreon)\.com/i;

const titleCase = (str: string): string => {
  return str ? startCase(lowerCase(str.trim())) : '';
};

const getCompanyNameFromSocialNetworkUrl = (url: string): string => {
  // getting the social network from the url
  const [, , socialNetwork] = url.match(SOCIAL_NETWORK_PAGE_REGEX);

  // defined regex for each social network
  const facebookRegex = /facebook\.com\/(pages\/)?([^/]*)(\/.*)?\/?$/i; // result will be in group 2
  const linkedinRegex = /linkedin\.com\/(in|company)\/([^/]*)\/?$/i; // result will be in group 2
  const instagramRegex = /instagram\.com\/([^/]*)\/?$/; // result will be in group 1
  const twitterRegex = /twitter\.com\/([^/]*)\/?$/; // result will be in group 1
  const patreonRegex = /patreon\.com\/([^/]*)\/?$/; // result will be in group 1

  // map social network to its regex and company name group position in the match
  const socialRegexMap: Record<SocialNetwork, { regex: RegExp; position: number }> = {
    facebook: { regex: facebookRegex, position: 2 },
    linkedin: { regex: linkedinRegex, position: 2 },
    instagram: { regex: instagramRegex, position: 1 },
    twitter: { regex: twitterRegex, position: 1 },
    patreon: { regex: patreonRegex, position: 1 },
  };

  let companyName = '';

  // we are doing here something that potentially can fail
  try {
    const regex = socialRegexMap?.[socialNetwork as SocialNetwork]?.regex;
    const position = socialRegexMap?.[socialNetwork as SocialNetwork]?.position;

    companyName = url.match(regex)[position];
  } catch (e) {
    // clientLogger.log('Failed retrieving company name from social network link');
    return url;
  }

  // companyName cleanup
  companyName = titleCase(companyName.replace(/-[\da-f]*$/i, ''));

  // prettify the company name
  return titleCase(companyName) || url;
};

/**
 * Return Company name out of the website url
 * fully supports following urls:
 *
 https://www.ukcampsite.co.uk/sites/index.asp
 https://en.wikipedia.org/wiki/List_of_World_Heritage_Sites_in_the_United_Kingdom
 https://www.campingandcaravanningclub.co.uk/ukcampsites
 https://uk.mydomain.com/ukcampsites
 https://uk.mydomain.co.uk/ukcampsites
 https://mail.walla.co.il/ukcampsites
 https://www.mydomain.co.uk/sdfsdf
 https://mysub.domain.tours/sdfsdf
 https://xn--fpcrj9c3d.tours/sdfsdf
 https://test.test.xn--fpcrj9c3d.tours/sdfsdf
 https://test.xn--fpcrj9c3d.dad/sd/sdf/sd/fs/df
 * @param url
 * @returns {string|*}
 */
const getCompanyNameFromUrl = (url: string): string => {
  let companyName = url;

  if (!url) {
    return companyName;
  }

  try {
    // Attempt to create a URL object from website url input
    const fullDomain = psl.get(new URL(url).hostname);

    // Trying to extract the domain name - might fail if invalid url
    const domain = fullDomain.split('.')[0];

    // Then prettify it
    companyName = titleCase(domain);
  } catch (e) {
    // If failed, set the name as the website address.
    companyName = '';
    // clientLogger.error(e);
    // clientLogger.error('Unable to set company name from website');
  }

  return companyName;
};

const getCompanyName = (websiteAddress: string | null | undefined, emailAddress: string): string => {
  let companyName = websiteAddress;

  if (typeof websiteAddress === 'string' && websiteAddress.trim().length > 0) {
    // check if the websiteAddress is a social network url
    if (SOCIAL_NETWORK_PAGE_REGEX.test(websiteAddress)) {
      companyName = getCompanyNameFromSocialNetworkUrl(websiteAddress);
    }

    // might be that we failed extracting company name then by default we return the websiteAddress
    // now we are doing what we usually do with the URL
    if (companyName === websiteAddress) {
      companyName = getCompanyNameFromUrl(websiteAddress);
    }
  } else {
    const localPart = emailAddress.split('@')?.[0];
    const emailDomain = getEmailDomain(emailAddress);

    companyName = !isFreeEmailDomain(emailAddress) ? emailDomain : localPart || '';
  }

  // generate a default company name just in case companyName || websiteAddress will be empty
  return companyName || websiteAddress; // || defaultCompanyName;
};

export default getCompanyName;

import {
  CompanyCreateInput,
  EmployeeCount,
  IndustryInput,
  MarketingServiceType,
  MediaType,
  ProductBusinessModel,
  ProjectCreateInput,
  ProjectLeadCreate,
  State,
  UserUpdateInput,
} from '@mayple/types';
import { v4 as uuidV4 } from 'uuid';

import { Lead, TargetedLocation } from '../types';
import { fixNumericValue, validateFieldsValuesTypes, validateMandatoryFields } from './validateLeadValues';
import {
  defaultValueFallBack,
  ESTIMATED_MEDIA_BUDGET_MAX_VALUE,
  getCompanyCreateExternalMetadata,
  getTargetedLocations,
  getUIBrandingType,
  getUniqueValuesInArray,
} from './logic';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

const mapLeadToProjectLeadCreate = (
  lead: Partial<Lead>,
  utmParams = {} as Record<string, string>,
): ProjectLeadCreate => {
  const {
    name,
    emailAddress,
    country,
    firstName = '',
    lastName = '',
    companyName,
    companyDescription = '',
    phoneNumber = '',
    websiteAddress = '',
    estimatedMediaBudget,
    locations = [] as TargetedLocation[],
    state = State.NONE,
    targetKPI,
    serviceTypes,
    productBusinessModel = [],
    languages = [],
    industry = [] as IndustryInput[],
    currency,
    preferLocalMarketers = false,
    preferMarketerInTargetedLocations = false,
    requestedProjectSpecialType,
    projectDeepBriefPreloadedAnswers,
    externalCustomerId,
    trafficSource,
  } = lead;

  // Wrapping the Lead validations with try / catch - just to report error to the console, but not break the lead
  // creation
  const mandatoryFieldsValidationResult = validateMandatoryFields(lead);
  if (!mandatoryFieldsValidationResult.isValid) {
    throw new Error(`Missing mandatory values ${mandatoryFieldsValidationResult.fields.join(', ')}.`);
  }

  const fieldsValuesValidationResult = validateFieldsValuesTypes(lead);
  if (!fieldsValuesValidationResult.isValid) {
    throw new Error(
      `Wrong values were passed to the following fields ${fieldsValuesValidationResult.fields.join(', ')}.`,
    );
  }

  const { locations: finalLocations, worldwideAudienceTargeting } = getTargetedLocations(locations);

  const projectCreate: ProjectCreateInput = {
    targetUrls: websiteAddress ? [websiteAddress] : [],
    projectDefinition: {
      primaryObjectives: { targetKPI },
    },
    requestedServices: (serviceTypes || []).map((serviceType: MarketingServiceType) => ({
      uuid: uuidV4().replace(/-/g, ''),
      serviceType,
      description: {
        mediaType: MediaType.TEXT_PLAIN,
        contents: '',
      },
    })),
    productBusinessModel: getUniqueValuesInArray(
      defaultValueFallBack<Array<ProductBusinessModel>>(productBusinessModel, []),
    ),
    languages: getUniqueValuesInArray(languages),
    locations: finalLocations,
    industry,
    worldwideAudienceTargetting: worldwideAudienceTargeting,
    currency,
    estimatedMediaBudget: Math.min(fixNumericValue(estimatedMediaBudget), ESTIMATED_MEDIA_BUDGET_MAX_VALUE),
    preferLocalMarketers,
    preferMarketerInTargettedLocations: preferMarketerInTargetedLocations,
  };

  if (name && typeof name === 'string' && name.trim().length > 0) {
    projectCreate.overriddenName = name.trim();
    projectCreate.useOverriddenName = true;
  }

  if (requestedProjectSpecialType) {
    projectCreate.requestedProjectSpecialType = requestedProjectSpecialType;
  }

  if (projectDeepBriefPreloadedAnswers) {
    try {
      projectCreate.projectDeepBriefPreloadedAnswers = JSON.parse(JSON.stringify(projectDeepBriefPreloadedAnswers));
    } catch (e) {
      throw new Error(`Wrong values were passed to projectDeepBriefPreloadedAnswers.`);
    }
  }

  const companyCreate: CompanyCreateInput = {
    name: companyName || '',
    description: companyDescription || '',
    displayImageUrl: '',
    externalMetadata: [],
    uiBrandingType: getUIBrandingType(requestedProjectSpecialType),
    companyProfile: {
      employeeCount: EmployeeCount.UNKNOWN,
      contactDetails: {
        postalAddress: {
          state,
          street1: '',
          street2: '',
          postCode: '',
          country: country,
          city: '',
        },
        phoneNumber: phoneNumber || '',
        alternativePhoneNumber: '',
        emailAddress: (emailAddress || '').toLowerCase().trim(),
        alternativeEmailAddress: '',
        websiteAddress: websiteAddress || '',
        linkedinProfileAddress: '',
      },
    },
  };

  companyCreate.externalMetadata = getCompanyCreateExternalMetadata(externalCustomerId, trafficSource, utmParams);

  const userUpdate: UserUpdateInput = { firstName, lastName, phoneNumber };

  const projectLeadCreate: ProjectLeadCreate = {
    projectCreate: pickBy(projectCreate, identity) as ProjectCreateInput,
    companyCreate,
    userUpdate,
  };

  return projectLeadCreate;
};

export default mapLeadToProjectLeadCreate;

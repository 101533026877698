import isEmail from 'validator/lib/isEmail';
import ClientLogger from '../../ClientLogger';

const getEmailDomain = (emailAddress: string, clientLogger?: ClientLogger) => {
  if (!emailAddress || !isEmail(emailAddress.trim())) {
    return '';
  }

  try {
    return emailAddress.trim().split('@')[1];
  } catch (e) {
    clientLogger?.error(e);
    return '';
  }
};

export default getEmailDomain;

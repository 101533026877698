import getEmailDomain from './getEmailDomain';
import hasMXRecord from './hasMXRecord';
import ClientLogger from '../../ClientLogger';

async function isValidEmailDomain(email: string, clientLogger?: ClientLogger): Promise<boolean> {
  const domain = getEmailDomain(email, clientLogger);
  if (!domain) {
    return false;
  }

  const hasRecord = await hasMXRecord(domain, clientLogger);
  if (!hasRecord) {
    return false;
  }

  return hasRecord;
}

export default isValidEmailDomain;

import { Country } from '@mayple/types';

const geoIpLookup = async (defaultCountry = Country.US): Promise<Country> => {
  try {
    const { protocol } = window.location;
    const response = await fetch(`${protocol}//ipinfo.io/json`, { method: 'GET', mode: 'cors' });
    const json = await response.json();
    // console.log('geoIpLookup response', json);
    let country = json?.country ?? defaultCountry;
    if (country === 'PS') {
      country = 'IL';
    }
    return (country || defaultCountry) as Country;
  } catch (e) {
    console.log(e);
    return defaultCountry as Country;
  }
};

export default geoIpLookup;

import { Country, ExternalMetadataKeyValueInput, ProjectSpecialType, UiBrandingType } from '@mayple/types';
import { TargetedLocation } from '../types';

export const ESTIMATED_MEDIA_BUDGET_MAX_VALUE = 10000000;

export const getUniqueValuesInArray = <T>(arr: T[]): T[] => {
  return Array.from(new Set(arr || []));
};

export const getProjectName = (requestedProjectSpecialType: ProjectSpecialType, companyName: string): string => {
  return requestedProjectSpecialType === ProjectSpecialType.MAILCHIMP_AUDIT_TYPE
    ? 'New Mailchimp Health Check'
    : `${companyName || 'New'} Project`;
};

export const getUIBrandingType = (requestedProjectSpecialType: ProjectSpecialType): UiBrandingType => {
  return requestedProjectSpecialType === ProjectSpecialType.MAILCHIMP_AUDIT_TYPE
    ? UiBrandingType.MAILCHIMP_EXPERIENCE
    : UiBrandingType.MAYPLE_EXPERIENCE;
};

// if the user has chosen WW, filter the value and set worldwideAudienceTargetting to true.
export const getTargetedLocations = (
  locations: TargetedLocation[],
): {
  locations: Country[];
  worldwideAudienceTargeting: boolean;
} => {
  let worldwideAudienceTargeting = false;
  const finalLocations: Country[] = [];
  locations.forEach((location) => {
    if (location === 'WW') {
      worldwideAudienceTargeting = true;
    } else {
      finalLocations.push(location as Country);
    }
  });

  return {
    locations: getUniqueValuesInArray(finalLocations),
    worldwideAudienceTargeting,
  };
};

export const defaultValueFallBack = <T>(value: T, defaultValue: T): T => {
  if (!value && typeof value != 'boolean') {
    return defaultValue;
  }
  if (value && Array.isArray(value) && !value?.length) {
    return defaultValue;
  }

  return value;
};

export const mapObjetToExternalMetadataInput = (
  obj: Record<string, string> | null | undefined,
): ExternalMetadataKeyValueInput[] => {
  if (!obj) {
    return [];
  }

  return Object.entries(obj).map(([key, value]) => ({ key: key, value: value }));
};

export const getCompanyCreateExternalMetadata = (
  externalCustomerId: string,
  trafficSource: string,
  utmParams?: Record<string, string>,
): ExternalMetadataKeyValueInput[] => {
  const externalMetadata: ExternalMetadataKeyValueInput[] = mapObjetToExternalMetadataInput(utmParams);

  if (externalCustomerId) {
    externalMetadata.push({
      key: 'externalCustomerId',
      value: externalCustomerId,
    });
  }

  if (trafficSource) {
    externalMetadata.push({
      key: 'trafficSource',
      value: trafficSource,
    });
  }

  return externalMetadata;
};

import * as Sentry from '@sentry/browser';
import { Country, CurrencyType, KpiType } from '@mayple/types';
import { FormSubmitResponse } from '@mayple/hubspot-form-submit-v3/build/types/types';

import { Lead, WebsiteSDKOptions } from './types';
import calcFrontendSalesQualificationScore from './calcFrontendSalesQualificationScore';
import ProjectLeadManager from './ProjectLeadManager';
import HubspotManager from './hubspot';
import reportEvent from './events';
import { getUtmParams, saveUtmParams } from './UTMParams';
import { ERROR_NOT_INITIALIZED, SENTRY_DSN } from './consts';
import { getEmailDomain, isFreeEmailDomain, isValidEmailDomain } from './emails';
import getAppRedirectUrl from './getAppRedirectUrl';
import geoIpLookup from '../geoIP';
import ClientLogger from '../ClientLogger';
import getCompanyName from './getCompanyName';
import { getSalesMeetingInfo, setSalesMeetingInfo } from './salesMeetingInformation';

class WebsiteSDK {
  private readonly debug;
  private readonly disableFormSubmission: boolean;
  private readonly clientLogger: ClientLogger;
  private readonly projectLeadManager: ProjectLeadManager;
  private readonly hubspotManager: HubspotManager;
  private readonly onReadyCallback: (p: this) => void;

  private _initialized: boolean;
  private _userCountry: Country;
  private _currency: CurrencyType;

  constructor(options?: WebsiteSDKOptions) {
    this.debug = options?.debug ?? false;

    this.disableFormSubmission = options?.disableFormSubmission ?? false;

    Sentry.init({
      dsn: SENTRY_DSN,
      environment: options?.environment,
      debug: this.debug,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          blockAllMedia: false,
          maskAllInputs: false,
          maskAllText: false,
        }),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.25,

      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    this.clientLogger = new ClientLogger(this.debug);

    this.projectLeadManager = new ProjectLeadManager(this.debug, options?.environment);

    this.hubspotManager = new HubspotManager({
      debug: this.debug,
      disableFormSubmission: this.disableFormSubmission,
      environment: options?.environment,
      formId: options?.formId,
      hubspotPortalId: options?.hubspotPortalId,
    });

    this.onReadyCallback = options?.onReadyCallback;

    geoIpLookup().then(
      ((country: Country) => {
        this._userCountry = country;
        this._currency = CurrencyType.USD;
        this._initialized = true;
        this.ready();
      }).bind(this),
    );
  }

  get userCountry() {
    return this._userCountry;
  }

  ready = () => {
    this.onReadyCallback?.(this);
  };

  enrichLeadData = (lead: Partial<Lead>): Partial<Lead> => {
    let enrichment: Partial<Lead> = {};

    // Add country data to lead
    // @ts-ignore
    if (!lead?.country) {
      enrichment = {
        ...enrichment,
        country: this._userCountry,
      };
    }

    if (!lead?.currency) {
      enrichment = {
        ...enrichment,
        currency: this._currency,
      };
    }

    if (!lead.targetKPI) {
      enrichment = {
        ...enrichment,
        targetKPI: KpiType.UNKNOWN,
      };
    }

    if (!lead?.frontendSalesQualificationScore) {
      enrichment = {
        ...enrichment,
        frontendSalesQualificationScore: calcFrontendSalesQualificationScore(lead, this.clientLogger),
      };
    }

    if (!lead.companyName) {
      enrichment = {
        ...enrichment,
        companyName: getCompanyName(lead?.websiteAddress, lead?.emailAddress),
      };
    }

    return {
      ...lead,
      ...enrichment,
    };
  };

  calcSalesQualificationLeadScore = (lead: Lead): number => {
    if (!this._initialized) {
      throw new Error(ERROR_NOT_INITIALIZED);
    }

    return calcFrontendSalesQualificationScore(this.enrichLeadData(lead), this.clientLogger);
  };

  createProjectLead = async (lead: Lead): Promise<boolean> => {
    if (!this._initialized) {
      throw new Error(ERROR_NOT_INITIALIZED);
    }

    const token = await this.projectLeadManager.createProjectLead(this.enrichLeadData(lead));
    return !!token;
  };

  createProjectLeadWithToken = async (lead: Lead): Promise<string | boolean> => {
    if (!this._initialized) {
      throw new Error(ERROR_NOT_INITIALIZED);
    }

    return this.projectLeadManager.createProjectLead(this.enrichLeadData(lead));
  };

  submitHubspotForm = async (
    lead: Partial<Lead>,
    formId?: string,
    hubspotPortalId?: string,
  ): Promise<FormSubmitResponse> => {
    if (!this._initialized) {
      throw new Error(ERROR_NOT_INITIALIZED);
    }
    const hubspotFormValues = this.hubspotManager.mapLeadToHubspotFormValues(this.enrichLeadData(lead));

    return this.hubspotManager.submitHubspotForm(hubspotFormValues, formId, hubspotPortalId);
  };

  getUtmParams = getUtmParams;

  saveUtmParams = saveUtmParams;

  reportEvent = reportEvent;

  isValidEmailDomain = async (email: string): Promise<boolean> => isValidEmailDomain(email, this.clientLogger);

  isFreeEmailDomain = isFreeEmailDomain;

  getEmailDomain = getEmailDomain;

  upgradeHubSpotCookie = () => {
    return this.hubspotManager.upgradeHubSpotCookie();
  };

  getAppRedirectUrl = getAppRedirectUrl;

  // Meeting information handlers (using Local Storage)
  getSalesMeetingInfo = getSalesMeetingInfo;
  setSalesMeetingInfo = setSalesMeetingInfo;
}

export default WebsiteSDK;

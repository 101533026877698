export const WW = 'WW';

export const ERROR_NOT_INITIALIZED =
  'WebsiteSDK is not initialized yet. You should only call methods when it is ready. Use the onReadyCallback to verify when module is initialized';

export const ENV = {
  PORKY: 'porky',
  STORKY: 'storky',
  TORKY: 'torky',
  DORKY: 'dorky',
};

export const SENTRY_DSN = 'https://7bd166f714a64bcda91e196fbb6f6a5c@o226092.ingest.sentry.io/6597154';

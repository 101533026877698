import cookies, { getTopDomain } from '../cookies';

const MAYPLE_UTM_PARAMS = '_mayple_utm_params';

const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term', 'gclid', 'gbraid', 'wbraid'];

const getUtmParamsFromUrl = (): Record<string, string | null> | null => {
  let search = '';

  // Try and extract utm params from top window object - for cases the code run inside an iframe
  try {
    search = window.top.location.search ?? window.location.search;
  } catch {
    // fall back to window.location object - for cases that we can't access window.top
    search = window.location.search || '';
  }

  try {
    const urlParams = new URLSearchParams(search);

    const utmParams: Record<string, string | null> = {};
    utmKeys.forEach((param) => {
      if (param != null && urlParams.has(param)) {
        utmParams[param] = urlParams.get(param);
      }
    });

    return utmParams;
  } catch (e) {
    return null;
  }
};

export const getUtmParams = (): Record<string, string | null> => {
  const utmParamsFromCookie = cookies.get(MAYPLE_UTM_PARAMS) as unknown as Record<string, string | null> | null;
  const utmParamsFromURL = getUtmParamsFromUrl();

  return utmParamsFromCookie || utmParamsFromURL || {};
};

export const checkUtmParamsIsValid = (utmParams: Record<string, string | null>): boolean => {
  if (!utmParams || typeof utmParams !== 'object') {
    return false;
  }

  return Object.keys(utmParams).length > 0;
};

export const saveUtmParams = (): void => {
  const utmParams = getUtmParamsFromUrl();

  const areValidUtmParams = checkUtmParamsIsValid(utmParams);

  if (utmParams && areValidUtmParams && !cookies.get(MAYPLE_UTM_PARAMS)) {
    const domain = getTopDomain();
    cookies.set(MAYPLE_UTM_PARAMS, utmParams, {
      domain,
      expires: 30,
      SameSite: 'Strict',
    });
  }
};

import { HubspotConfiguration } from './types';
import { ENV } from '../consts';

export const HUBSPOT_UTK_COOKIE = 'hubspotutk';

export const HUBSPOT_LAST_ACTION_IN_MAYPLE = {
  CONTACT_SUBMITTED: 'Contact submitted',
  PROJECT_LEAD_CREATED: 'Project Lead created',
};

export const hubspotConfigurations: Record<string, HubspotConfiguration> = {
  [ENV.PORKY]: { HUBSPOT_PORTAL_ID: '4292856', HUBSPOT_MAYPLE_APP_FORM_ID: '9c266462-4b62-4255-bd93-7bbf92103f72' },
  [ENV.STORKY]: { HUBSPOT_PORTAL_ID: '5249128', HUBSPOT_MAYPLE_APP_FORM_ID: '417ab7e9-9fab-495b-a8d8-a6a8ad06587d' },
  [ENV.TORKY]: { HUBSPOT_PORTAL_ID: '20738639', HUBSPOT_MAYPLE_APP_FORM_ID: '301ec5c6-86b1-4bf6-8b3e-3462227c8ade' },
  [ENV.DORKY]: { HUBSPOT_PORTAL_ID: '5560072', HUBSPOT_MAYPLE_APP_FORM_ID: 'e9699a52-a362-454b-8d2c-61c30b203b4e' },
};

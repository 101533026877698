class ClientLogger {
  private _debugMode = false;
  private logger = console;

  constructor(debugMode = false, logger = console) {
    this._debugMode = debugMode;
    this.logger = logger;
  }

  get debugMode() {
    return this._debugMode;
  }

  set debugMode(newDebugMode) {
    this._debugMode = newDebugMode;
  }

  log = (message: any, ...optionalParams: any[]) => {
    if (!this._debugMode) {
      return;
    }
    this.logger.log(message, ...optionalParams);
  };

  error = (message: any, ...optionalParams: any[]) => {
    if (!this._debugMode) {
      return;
    }
    this.logger.error(message, ...optionalParams);
  };

  debug = (message: any, ...optionalParams: any[]) => {
    if (!this._debugMode) {
      return;
    }
    this.logger.info(message, ...optionalParams);
  };

  info = (message: any, ...optionalParams: any[]) => {
    if (!this._debugMode) {
      return;
    }
    this.logger.info(message, ...optionalParams);
  };
}

export default ClientLogger;

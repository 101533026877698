import freeEmailDomains from 'free-email-domains';
import { getEmailDomain } from './index';

const isFreeEmailDomain = (emailAddress: string) => {
  const emailDomain = getEmailDomain(emailAddress);

  return freeEmailDomains.includes(emailDomain);
};

export default isFreeEmailDomain;

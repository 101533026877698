import {
  Country,
  CurrencyType,
  IndustryInput,
  KpiType,
  MarketingLimitationLanguage,
  MarketingServiceType,
  ProductBusinessModel,
  ProjectSpecialSubType,
  ProjectSpecialType,
  State,
} from '@mayple/types';
import WebsiteSDK from './WebsiteSDK';

export enum HubspotPersona {
  COMPANY_PERSONA = 'persona_1',
  MARKETER_PERSONA = 'persona_2',
}

export type WebsiteSDKOptions = {
  debug?: boolean;
  environment?: string;
  formId?: string;
  hubspotPortalId?: string;
  disableFormSubmission?: boolean;
  onReadyCallback?: (w: WebsiteSDK) => void;
};

export type Lead = {
  emailAddress: string; // required - user email address
  firstName?: string; // optional - the user first name
  lastName?: string; // optional - the user last name
  country?: Country; // optional - the user country code - getting filled automatically by user ip location if not supplied
  state?: State; // optional - defaults to State.NONE
  phoneNumber?: string; // optional - the phone number of the user in E.164 format
  name?: string; // optional project name to override the default project name generate
  currency?: CurrencyType; // optional - defaults to CurrencyType.USD
  targetKPI?: KpiType; // optional - if not supplied defaults to KpiType.UNKNOWN
  serviceTypes?: MarketingServiceType[]; // optional - list of MarketingServiceType
  companyName?: string; // optional way to set the Company Name
  companyDescription?: string; // optional - way to set the Company Description
  websiteAddress?: string; // optional - the main target url of the company - can be used to extract company name (if company name is not supplied)
  productBusinessModel?: ProductBusinessModel[]; // optional - B2B / B2C
  languages?: MarketingLimitationLanguage[]; // optional - languages that the expert should talk
  locations?: TargetedLocation[]; // optional - targeted locations where the client audience is located
  industry?: IndustryInput[]; // optional - the company industry
  estimatedMediaBudget?: number; // optional - value defaults to 0
  preferLocalMarketers?: boolean; // optional - way to set the matching preference to use only experts from the user's country
  preferMarketerInTargetedLocations?: boolean; // optional - way to set the matching preference to use only experts from the user's selected locations
  hubSpotPersona?: HubspotPersona; // optional - but should be set when submitting data to HS
  skills?: MarketingServiceType[]; // optional - list of required marketing skills from the expert
  requestedProjectSpecialType?: ProjectSpecialType; // optional - used with Expert Platform projects - to set ProjectSpecialType.
  requestedProjectSpecialSubType?: ProjectSpecialSubType; // optional - used with Expert Platform projects - to set ProjectSpecialSubType.

  // lead props related to HS
  hubspotFormProps?: Record<string, any>; // a key value object to update HS properties
  lastActionInMayple?: string; // optional - (not sure if used, most likely for HS)
  didFillBasicBrief?: string; // optional - (not sure if used, most likely for HS)
  lastUpdateContext?: string; // optional - (not sure if used, most likely for HS)
  token?: string; // optional - used by getContinueSessionLink - (not sure if used, most likely for HS)
  howDidYouHearAboutMayple?: string; // optional - (not sure if used, most likely for HS)
  trafficSource?: string; // optional - used in HS to mark the lead source
  lpTrafficSource?: string; // optional - used in HS to mark the lead source
  partnerKey?: string; // optional - used in HS
  partnerStackReferralKey?: string; // optional - used in HS (should probably be moved to hubspotFormProps)
  requestedAnInstantCall?: string; // optional - used in HS (should probably be moved to hubspotFormProps)
  frontendSalesQualificationScore?: number; // optional - not in use anymore. Automatically calculated out of the lead data if not supplied.
  projectDeepBriefPreloadedAnswers?: Record<string, any>; // optional - a key value object to update Deep Brief questions data
  externalCustomerId?: string; // optional - a string to help identify leads from partners on Expert Platform

  // UTM data is automatically extracted from URL or Mayple UTM cookie.
  utm_source?: string; // optional - a way to override UTM source data.
  utm_medium?: string; // optional - a way to override UTM medium data.
  utm_campaign?: string; // optional - a way to override UTM campaign data.
  utm_content?: string; // optional - a way to override UTM content data.
  utm_term?: string; // optional - a way to override UTM term data.
};

export type TargetedLocation = Country | 'WW';

export enum EmailType {
  personalEmail,
  companyEmail,
}

export enum Environment {
  porky = 'porky',
  storky = 'storky',
  torky = 'torky',
  dorky = 'dorky',
}

export type MeetingPayload = {
  linkType: string;
  offline: true;
  userSlug: string;
  formGuid: string;
  bookingResponse: {
    event: {
      duration: number;
      formFields: Array<Record<string, any>>;
      dateString: string;
      dateTime: number;
      bookingTimeConflictStatus: boolean;
    };
    postResponse: {
      timerange: {
        start: number;
        end: number;
      };
      organizer: {
        firstName: string;
        lastName: string;
        email: string;
        fullName: string;
        name: string;
        userId: string | null;
      };
      bookedOffline: boolean;
      contact: {
        firstName: string;
        lastName: string;
        email: string;
        fullName: string;
        name: string;
        userId: string | null;
      };
    };
  };
  isPaidMeeting: boolean;
};

declare global {
  interface Window {
    mayple_analytics: any;
    __APOLLO_CLIENT__: any;
    FS: any;
    _hsq: any;
  }
}

import Cookies from 'js-cookie';

const decode = (s: string): string => {
  return s.replace(/(%[0-9A-Z]{2})+/g, decodeURIComponent);
};

const cookies = Cookies.withConverter({
  read: (value) => {
    return JSON.parse(decode(value));
  },
  write: (value) => {
    return JSON.stringify(value);
  },
});

export const getTopDomain = (withPort = false): string | undefined => {
  if (!window) {
    return undefined;
  }

  const domain = window.location.hostname.replace(/^(app|welcome|www|lp|cpanel)\./, '');
  const port = window.location.port !== '' ? window.location.port : '';
  return port && withPort ? `${domain}:${port}` : domain;
};

export const getCookieOptions = (isDebug: boolean, expires = 365) => {
  const domain = getTopDomain();

  if (isDebug) {
    return {
      domain,
      expires: 10,
      SameSite: 'Lax',
    };
  }

  return {
    domain,
    expires,
    SameSite: 'None',
    secure: true,
  };
};

export default cookies;

import { ApolloClient } from 'apollo-client';
import * as Sentry from '@sentry/browser';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';

import createProjectLeadToken from './createProjectLeadToken';
import mapLeadToProjectLeadCreate from './mapLeadToProjectLeadCreate';
import saveProjectLeadTokenToCookie from './saveProjectLeadTokenToCookie';

import { Lead } from '../types';
import { getUtmParams } from '../UTMParams';
import { getApolloClient } from '../../Apollo';
import ClientLogger from '../../ClientLogger';

class ProjectLeadManager {
  private readonly debug: boolean;
  private readonly environment?: string;
  private readonly apolloClient: ApolloClient<NormalizedCacheObject>;
  private readonly clientLogger;

  constructor(debug = false, environment?: string) {
    this.debug = debug;
    this.environment = environment;
    this.apolloClient = getApolloClient(this.environment);
    this.clientLogger = new ClientLogger(this.debug);
  }

  createProjectLead = async (lead: Partial<Lead>): Promise<string | boolean> => {
    try {
      const projectLeadCreate = mapLeadToProjectLeadCreate(lead, getUtmParams());
      const token: string = await createProjectLeadToken(this.apolloClient, projectLeadCreate, this.clientLogger);
      saveProjectLeadTokenToCookie(token, this.clientLogger, this.debug);
      return token;
    } catch (e) {
      Sentry.captureException(e);
      this.clientLogger.error(e);
      return false;
    }
  };
}

export default ProjectLeadManager;

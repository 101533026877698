import Cookies from 'js-cookie';

import { getCookieOptions } from '../cookies';

const MAYPLE_ALTERNATIVE_EMAIL_ADDRESS_COOKIE = '_mayple_aea';

/**
 * create Alternative email address cookie
 */
export const createAlternativeEmailCookie = (email: string, isDebug = false) => {
  if (!email) {
    return;
  }

  const options = getCookieOptions(isDebug, 30);
  // saving new generated user id
  Cookies.set(MAYPLE_ALTERNATIVE_EMAIL_ADDRESS_COOKIE, email, options);
};

import axios from 'axios';
import ClientLogger from '../../ClientLogger';
import * as Sentry from '@sentry/browser';

type SimpleRecord = {
  name: string;
  type: number;
};

type SimpleDomainResponse = {
  Question: SimpleRecord;
  Answer: SimpleRecord[];
};

const MX = 15;

async function hasMXRecord(domain: string, clientLogger?: ClientLogger): Promise<boolean | null> {
  try {
    const { data } = await axios.get<SimpleDomainResponse>(
      // https://developers.google.com/speed/public-dns/docs/doh
      `https://dns.google/resolve`,
      {
        timeout: 10000,
        params: { name: domain, type: MX },
        headers: {
          Accept: 'application/json',
        },
      },
    );
    if (data?.Answer?.length != undefined && data?.Answer?.length > 0) {
      return data.Answer.filter((record: SimpleRecord) => record.type == MX).length > 0;
    } else {
      // No data returned from the service, we will return true
      return false;
    }
  } catch (error) {
    // Error happened when trying to access dns.google, we return true to skip the dns validation
    if (axios.isAxiosError(error)) {
      Sentry.captureException(error);
      clientLogger.error('error message: ', error.message);
      return true;
    } else {
      Sentry.captureException(error);
      clientLogger.error('unexpected error: ', error);
      return true;
    }
  }
}

export default hasMXRecord;

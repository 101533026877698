import { FormSubmitError, FormSubmitResponse, SubmitStatus } from '@mayple/hubspot-form-submit-v3/build/types/types';

class HubspotFormSubmitError extends Error {
  private readonly errors: FormSubmitError[];
  private readonly status: SubmitStatus;

  constructor(formSubmitResponse: FormSubmitResponse) {
    super(formSubmitResponse.message);
    this.name = 'HubspotFormSubmitError';
    this.errors = formSubmitResponse.errors;
    this.status = formSubmitResponse.status;
  }
}

export default HubspotFormSubmitError;

import { hubspotConfigurations } from './consts';
import { HubspotConfiguration } from './types';
import { ENV } from '../consts';

export const getHubspotConfigurations = (environment = ENV.PORKY): HubspotConfiguration => {
  const environments = Object.values(ENV);
  if (environments.includes(environment)) {
    return hubspotConfigurations[environment];
  }
  // by default return porky configuration
  return hubspotConfigurations[ENV.PORKY];
};

export const validateParams = (formId: string, hubspotPortalId: string): boolean => {
  return !(!formId || !hubspotPortalId);
};

const reportEvent = (eventName: string, traits: Record<string, any> = {}): void => {
  if (!window.mayple_analytics) {
    throw new Error('mayple_analytics is not loaded in the page yet. please wait for it to initialize first.');
  }

  if (!eventName) {
    throw new Error('No eventName param was sent to reportEvent()');
  }

  // We always want to have a structure of: (eventName = category + ' ' + action);
  const [category, action] = eventName.split(' ');

  if (!category || !action) {
    throw new Error(`No category or action found for ${eventName} event.`);
  }

  window.mayple_analytics.track(eventName, {
    category,
    action,
    ...traits,
  });
};

export default reportEvent;

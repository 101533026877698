import Cookies from 'js-cookie';
import { getCookieOptions } from '../cookies';
import ClientLogger from '../../ClientLogger';

// Cookies constants
export const LEAD_TOKEN_COOKIE = '_mayple_d_plt';

const saveProjectLeadTokenToCookie = (token: string, clientLogger: ClientLogger, isDebug: boolean) => {
  if (!token) {
    throw new Error('Token is missing');
  }

  const options = getCookieOptions(isDebug);

  Cookies.set(LEAD_TOKEN_COOKIE, token, options);

  clientLogger.debug(`Saving cookie for top level domain: ${options?.domain}`);
};

export default saveProjectLeadTokenToCookie;

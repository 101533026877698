import pickBy from 'lodash/pickBy';
import Cookies, { CookieAttributes } from 'js-cookie';
import * as Sentry from '@sentry/browser';

import hubspotFormSubmit, { HubspotField } from '@mayple/hubspot-form-submit-v3';

import { HubspotFormFieldsExtended, HubspotIdentityData, HubspotManagerOptions } from './types';
import { HUBSPOT_UTK_COOKIE } from './consts';
import { getHubspotConfigurations, validateParams } from './logic';

import { Lead } from '../types';
import { getUtmParams } from '../UTMParams';
import ClientLogger from '../../ClientLogger';
import { ENV } from '../consts';
import { FormSubmitResponse } from '@mayple/hubspot-form-submit-v3/build/types/types';
import HubspotFormSubmitError from './HubspotFormSubmitError';
import mapLeadToHubspotFormValues from './mapLeadToHubspotFormValues';
import { getContinueSessionLink } from './formDataHelpers';
import { createAlternativeEmailCookie } from '../emails/alternativeEmailAddress';
import { getTopDomain } from '../cookies';

class HubspotManager {
  private readonly formId: string;

  private readonly debug: boolean;

  private readonly environment: string;

  private readonly hubspotPortalId: string;

  private readonly disableFormSubmission: boolean;

  private readonly clientLogger: ClientLogger;

  constructor(options: HubspotManagerOptions) {
    this.debug = options?.debug || false;
    this.disableFormSubmission = options?.disableFormSubmission || false;
    this.environment = options?.environment || ENV.PORKY;
    this.clientLogger = new ClientLogger(this.debug);

    const { HUBSPOT_MAYPLE_APP_FORM_ID, HUBSPOT_PORTAL_ID } = getHubspotConfigurations(this.environment);

    this.formId = options?.formId || HUBSPOT_MAYPLE_APP_FORM_ID;
    this.hubspotPortalId = options?.hubspotPortalId || HUBSPOT_PORTAL_ID;

    // this.disableFormSubmission = this.environment === ENV.DORKY || this.environment === ENV.TORKY;
  }

  mapLeadToHubspotFormValues = (lead: Partial<Lead>): HubspotFormFieldsExtended => {
    const utmParams = getUtmParams();
    const hubspotFormFields = mapLeadToHubspotFormValues(lead, utmParams);

    // Update continueSessionLink
    const continueSessionLink = getContinueSessionLink(lead?.token, lead?.emailAddress);
    if (hubspotFormFields && continueSessionLink) {
      hubspotFormFields.continue_session_link = continueSessionLink;
    }

    // Update the FullStory session URL
    const fullStorySessionUrl = window?.FS?.getCurrentSessionURL?.();
    if (hubspotFormFields && fullStorySessionUrl) {
      hubspotFormFields.latest_fullstory_session = fullStorySessionUrl;
    }

    // Add maypleUniversalTrackingId from mayple_analytics if exists on page
    const maypleUniversalTrackingId = window?.mayple_analytics?._userId;
    if (hubspotFormFields && maypleUniversalTrackingId) {
      hubspotFormFields.mayple_universal_tracking_id = maypleUniversalTrackingId;
    }

    if (this.disableFormSubmission) {
      this.clientLogger.debug(`Sending values to hubspot form:\n\n${JSON.stringify(hubspotFormFields, null, 2)}`);
    }

    return hubspotFormFields;
  };

  mapHubspotFormFieldsToHubSpotFields = (fields: HubspotFormFieldsExtended) =>
    Object.entries(fields).map(
      ([key, value]) =>
        ({
          name: key,
          value,
        }) as HubspotField,
    );
  /**
   * Submitting hubspot form
   *
   * @param values
   * @param formId
   * @param hubspotPortalId
   */
  submitHubspotForm = async (
    values: HubspotFormFieldsExtended,
    formId = this.formId,
    hubspotPortalId = this.hubspotPortalId,
  ): Promise<any> => {
    if (!validateParams(formId, hubspotPortalId)) {
      this.clientLogger.error('Missing formId, or hubspotPortalId parameters.');
      throw new Error('Missing formId, or hubspotPortalId parameters.');
    }

    if (this.disableFormSubmission) {
      this.clientLogger.info('Skipping submission of Hubspot form in non-Porky/Storky environment: ', values);
      return true;
    }

    // Skip form submissions for mayple employees
    const email = values?.email || '';
    if (email && email.length && (email.endsWith('@mayple.com') || email.endsWith('@selectom.com'))) {
      this.clientLogger.info('Skipping submission of Hubspot form for @mayple.com emails.');
      return true;
    }

    try {
      // create Alternative Email Cookie when submitting data to hubspot
      createAlternativeEmailCookie(email, this.debug);
    } catch {
      this.clientLogger.error('Failed to create alternative email cookie.');
    }

    try {
      // Filter out null or undefined values
      const filteredValues = pickBy(values, (value) => value !== null && value !== undefined);

      this.clientLogger.debug(`Submitting to Hubspot form ${this.formId} the values:`, filteredValues);

      const hubspotFields = this.mapHubspotFormFieldsToHubSpotFields(filteredValues as HubspotFormFieldsExtended);

      // hubspotFormSubmit func returns Boolean true for success.
      return hubspotFormSubmit(this.hubspotPortalId, this.formId, hubspotFields)
        .then((result: FormSubmitResponse) => {
          if (result.status === 'error') {
            this.clientLogger.error(result);
            Sentry.captureException(new HubspotFormSubmitError(result));
          }
          return result;
        })
        .catch((error: any) => {
          this.clientLogger.error(error, `Failed Form ID: ${this.formId}, values: ${JSON.stringify(values)}`);
          Sentry.captureException(error);
          return error;
          // throw new Error('Failed submitting values to Hubspot form.');
        });
    } catch (error) {
      this.clientLogger.error(error, `Failed Form ID: ${this.formId}, values: ${JSON.stringify(values)}`);
      Sentry.captureException(error);
      return error;
    }
  };

  /**
   * Identify user in hubspot
   *
   * @param emailAddress
   * @param displayName
   * @param isCompany
   * @param isMarketer
   */
  identifyHubspotUser = (emailAddress: string, displayName: string, isCompany = false, isMarketer = false) => {
    // eslint-disable-next-line no-multi-assign,no-underscore-dangle
    const _hsq = (window._hsq = window._hsq || []);

    const identityData: HubspotIdentityData = {
      email: emailAddress,
    };

    if (displayName) {
      identityData.displayName = displayName;
    }
    if (isCompany) {
      identityData.isCompany = true;
    }
    if (isMarketer) {
      identityData.isMarketer = true;
    }

    _hsq.push(['identify', identityData]);
  };

  /**
   * Upgrade the Hubspot cookie, from potentially being on a subdomain to the top-level domain
   */
  upgradeHubSpotCookie = () => {
    try {
      // First we try and get the cookie, from the sub-domain or top-level domain
      const hubspotUtkValue = Cookies.get(HUBSPOT_UTK_COOKIE);
      if (!hubspotUtkValue) {
        return false;
      }

      // Then we set it to the top-level domain. As explained here:
      // https://knowledge.hubspot.com/articles/kcs_article/reports/what-cookies-does-hubspot-set-in-a-visitor-s-browser
      // It expires in 13 months.
      // See: https://github.com/js-cookie/js-cookie
      // for additional options
      const domain = getTopDomain();
      const options: CookieAttributes = {
        domain: `.${domain}`,
        expires: 13 * 30,
        path: '/',
        sameSite: 'lax',
      };

      Cookies.set('hubspotutk', hubspotUtkValue, options);
    } catch (e) {
      this.clientLogger.error(e, 'Error upgrading hubspotutk cookie.');
      return false;
    }

    return true;
  };
}

export default HubspotManager;

import { get, set } from 'local-storage';

import { MeetingPayload } from './types';

const MEETING_PAYLOAD_LOCAL_STORAGE_KEY = 'mayple_sales_meeting_info';

const getSalesMeetingInfo = (): MeetingPayload => get<MeetingPayload>(MEETING_PAYLOAD_LOCAL_STORAGE_KEY);

const setSalesMeetingInfo = (value: MeetingPayload | null): void => {
  set<MeetingPayload>(MEETING_PAYLOAD_LOCAL_STORAGE_KEY, value);
};

export { getSalesMeetingInfo, setSalesMeetingInfo };

// @ts-ignore
import freeEmailDomains from 'free-email-domains';

import { EmailType, Lead } from './types';
import { getEmailDomain } from './emails';
import ClientLogger from '../ClientLogger';

export const useNewScore = true;

const oldBudgetScoreMap = [
  {
    max: 0,
    score: -4,
  },
  {
    max: 999,
    score: -7,
  },
  {
    max: 4999,
    score: -4,
  },
  {
    max: 6999,
    score: 1,
  },
  {
    max: 9999,
    score: 3,
  },
  {
    max: 29999,
    score: 2,
  },
  {
    max: 100000000,
    score: 0,
  },
];

const budgetScoreMap = {
  [EmailType.personalEmail]: [
    {
      max: 0,
      score: -7,
    },
    {
      max: 2999,
      score: -4,
    },
    {
      max: 100000000,
      score: 1,
    },
  ],
  [EmailType.companyEmail]: [
    {
      max: 0,
      score: -5,
    },
    {
      max: 2999,
      score: 2,
    },
    {
      max: 100000000,
      score: 3,
    },
  ],
};

export const isFreeEmail = (emailDomain: string): boolean => {
  if (!emailDomain) {
    return false;
  }

  return freeEmailDomains.includes(emailDomain.toLowerCase());
};

const getAddReduceLabel = (value: number): string => (value < 0 ? 'Reducing' : 'Adding');

export const getBudgetScore = (estimatedMediaBudget: number, isPersonalEmail = false): number => {
  const emailType: EmailType = isPersonalEmail ? EmailType.personalEmail : EmailType.companyEmail;
  let budgetScore;

  if (useNewScore) {
    budgetScore = budgetScoreMap[emailType].find(({ max }) => estimatedMediaBudget <= max);
  } else {
    budgetScore = oldBudgetScoreMap.find(({ max }) => estimatedMediaBudget <= max);
  }

  return budgetScore ? budgetScore.score : 0;
};

export const getWebsiteAddressScore = (websiteAddress: string) => {
  if (websiteAddress) {
    return 3;
  }

  return -7;
};

const highPriorityCountries = new Set([
  'US', // US
  'CA', // Canada
]);

const highPriorityTargetLocations = new Set([
  'AU', // Australia
  'US', // US
  'CA', // Canada
  'GB', // UK
  'UK', // UK
  'IL', // Israel
]);

const mustHaveTargetLocations = new Set(['US', 'CA', 'GB', 'UK', 'IL', 'AU', 'NZ']);

const tier1Countries = new Set([
  'GB', // UK
  'UK', // UK
  'IL', // Israel
  'IE', // Ireland
  'ES', // Spain
  'FR', // France
  'DE', // Germany
  'BE', // Belgium
  'PT', // Portugal
  'IT', // Italy
  'NL', // Netherlands
  'DK', // Denmark
  'SE', // Sweden
  'NZ', // New Zealand
  'CH', // Switzerland
  'NH',
  'LU',
  'NO', // Norway
  'FI',
]);

/**
 * Calculates the mayple marketing score of the lead.
 * Can be used to determine the next step of the flow.
 * Based on https://mayple.slite.com/app/channels/9GIo6bhXuM/notes/IxWEIlXbpz
 * @returns {number}
 * @param lead
 * @param clientLogger
 */
const calcFrontendSalesQualificationScore = (lead: Partial<Lead>, clientLogger?: ClientLogger): number => {
  let frontendSalesQualificationScore = 0;

  const { locations = [], estimatedMediaBudget = 0, websiteAddress = '', country } = lead;

  const isHighPriorityCountry = highPriorityCountries.has(country);
  const isTier1Country = tier1Countries.has(country);
  const locationsTemp = [...locations];
  const isNotTargetingMustHaveLocation = locationsTemp.every((location) => !mustHaveTargetLocations.has(location));

  /* Scoring system */
  const emailDomain = getEmailDomain(lead.emailAddress);

  // If location of company is high location
  if (isHighPriorityCountry) {
    clientLogger?.debug(`Adding 2 points for country ${country}`);
    frontendSalesQualificationScore += 2;
    // If location of company is tier 1 location
  } else if (isTier1Country) {
    clientLogger?.debug(`Adding 0 points for country ${country}`);
    frontendSalesQualificationScore += 0;
    // If neither
  } else {
    clientLogger?.debug(`Removing 2 points for country ${country}`);
    frontendSalesQualificationScore -= 2;
  }

  // If targeting high priority location or WW
  for (const location of locationsTemp) {
    if (highPriorityTargetLocations.has(location)) {
      clientLogger?.debug(`Adding 2 points for target locations ${locations}`);
      frontendSalesQualificationScore += 2;
      break;
    }
  }

  if (isNotTargetingMustHaveLocation) {
    clientLogger?.debug(`Removing 2 points for target locations ${locations}`);
    frontendSalesQualificationScore -= 2;
  }

  // Budget score
  const budgetScore = getBudgetScore(estimatedMediaBudget, isFreeEmail(emailDomain));
  frontendSalesQualificationScore += budgetScore;
  clientLogger?.debug(`Adding ${budgetScore} points for budget: ${estimatedMediaBudget}`);

  // Filled website
  const websiteAddressScore = getWebsiteAddressScore(websiteAddress);
  frontendSalesQualificationScore += websiteAddressScore;
  clientLogger?.debug(
    `${getAddReduceLabel(websiteAddressScore)} ${websiteAddressScore} points for website: ${
      websiteAddress || 'no website address'
    }`,
  );

  clientLogger?.debug(`Total frontendSalesQualificationScore: ${frontendSalesQualificationScore}`);

  return frontendSalesQualificationScore;
};

export default calcFrontendSalesQualificationScore;

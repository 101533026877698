import { IndustryInput, MarketingServiceType, ProductBusinessModel } from '@mayple/types';
import { TargetedLocation } from '../types';
import { WW } from '../consts';

export const getSkills = (skills: MarketingServiceType[]): string =>
  skills && Array.isArray(skills) ? Array.from(new Set(skills)).join(', ') : '';

export const getCountries = (locations: TargetedLocation[]): string =>
  locations && Array.isArray(locations)
    ? Array.from(new Set(locations))
        .filter((location) => location !== WW)
        .join(', ')
    : '';

export const getProductBusinessModels = (productBusinessModel: ProductBusinessModel[]): string =>
  productBusinessModel && productBusinessModel.length ? productBusinessModel.join(', ') : '';

// If no token sent to func, it will send only the link to client, if token exists, then it will attach the
// query string to the client URL
export const getContinueSessionLink = (token: string, email: string): string =>
  `${window.location.origin}${token ? `?token=${token}` : ''}${token && email ? `&email=${email}` : ''}`;

export const getIndustry = (industries: IndustryInput[]) => {
  const mainIndustries =
    industries &&
    Array.isArray(industries) &&
    industries?.length &&
    (industries || []).map(({ industryCategory }) => {
      return industryCategory;
    });

  return mainIndustries?.length ? mainIndustries.join(', ') : '';
};
